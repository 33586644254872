import apiIndex from "../apis"
import SignupInstance from "../instance"

export interface sendEmail{
  email: string
}

interface sendEmailResponse{
  email:string,
  otp:number
}

export default async function sendEmail(data: sendEmail) {
  return await SignupInstance.post<sendEmailResponse>(apiIndex.sendEmail,data)
}
