import { FieldDataService, Validators } from "@src/modules/FieldData/FieldData";
import StateUtils from "@src/modules/StateManagement/Core/StateUtils";

export default class Validate extends StateUtils<Login.State> {
	validateUserName() {
		const verdict = { isValid: true };
		const data = this.state.userName;
		data.error = FieldDataService.registerValidator(
			data.value,
			verdict,
			Validators.validateNull
		);
		data.isValid = !data.error;

		this.mutateState((p) => {
			p.userName = data;
		});

		return verdict.isValid;
	}
	validatePassword() {
		const verdict = { isValid: true };
		const data = this.state.password;
		console.log(this.state.password);
		data.error = FieldDataService.registerValidator(
			data.value,
			verdict,
			Validators.validateNull
		);
		data.isValid = !data.error;

		this.mutateState((p) => {
			p.password = data;
		});

		return verdict.isValid;
	}

	validateLogin() {
		return this.validateUserName() && this.validatePassword();
	}
}
