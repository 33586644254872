import { Box } from "@mui/material";
import { useField } from "formik";
import style from "./SignupInput.module.css";
import React from "react";

interface SignupInputProps {
	label: string;
	name: string;
	placeholder?: string;
	marginBottom?: number;
	type?: string;
}

function SignupInput({ label, marginBottom, ...props }: SignupInputProps) {
	const { name } = props;
	const [field, meta, helpers] = useField(name);

	return (
		<Box width={"100%"} marginBottom={marginBottom}>
			<Box marginBottom={"10px"}>
				<p className="body fw-bold">{label}</p>
			</Box>
			<Box
				display={"flex"}
				height={48}
				padding={1}
				borderRadius={"8px"}
				sx={{ border: "1px solid var(--text-subtitle)", overflow: "hidden" }}
				marginBottom={0.5}
			>
				<input
					{...field}
					{...props}
					style={{ outline: "none", border: "none", width: "80%" }}
					className={"body " + style.input}
				/>
			</Box>
			{meta.error && meta.touched && (
				<p className="body" style={{ color: "red", fontSize: 14 }}>
					{meta.error}
				</p>
			)}
		</Box>
	);
}

export default SignupInput;
