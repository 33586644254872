import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	MobileStepper,
	Select,
} from "@mui/material";
import { Formik } from "formik";
import { FormikValues, FormikHelpers } from "formik/dist/types";
import React from "react";
import * as yup from "yup";
import axios from "axios";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import FormHeader from "@src/components/FormHeader.tsx/FormHeader";
import DetailsFormFields from "../../stateManagement/Types/DetailsFormFields";
import SignupInput from "@src/components/SignupInput/SignupInput";
import AssetIndex from "@src/assets/AssetIndex";
import checkEmailUnique from "@src/screens/Signup/fetch/services/checkEmailUnique";
import RoleIndex from "@src/modules/authorization/Roles.enum";

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
	firstName: yup.string().trim().required().max(20, "must be less than 50"),
	lastName: yup.string().max(20, "must be less than 50"),
	email: yup
		.string()
		.email()
		.required()
		.test("email-check", "email has already been taken", (v) =>
			checkEmailUnique(v)
				.then((res) => {
					return res.data;
				})
				.catch((err) => {
					return false;
				})
		),
	phoneNumber: yup
		.string()
		.matches(phoneRegExp, "phone number is not valid")
		.length(10, "phone number length is not valid")
		.required(),
});

function FirstForm() {
	const { actions } = useSignupFormContext();
	const formData = actions.getData();
	return (
		<FormContainer>
			<>
				<FormHeader
					heading={"Your Details"}
					subHeading={"Please provide your name and email"}
					icon={<AssetIndex.FileLock />}
				/>
				<Box>
					<Formik
						initialValues={{
							firstName: formData.firstName,
							lastName: formData.lastName,
							email: formData.email,
							phoneNumber: formData.phoneNumber,
						}}
						onSubmit={function (
							values: FormikValues,
							formikHelpers: FormikHelpers<FormikValues>
						): void | Promise<any> {
							actions.detailsFormActions.setDetails(
								values as DetailsFormFields
							);
							actions.setCurrentStep("otpVerification");
						}}
						validationSchema={validationSchema}
						validateOnMount={true}
					>
						{(formikProps) => {
							return (
								<React.Fragment key={1}>
									<SignupInput
										name={"firstName"}
										label={"First name *"}
										placeholder={"Enter your first name"}
										marginBottom={1.8}
									/>
									<SignupInput
										name={"lastName"}
										label={"Last Name *"}
										placeholder={"Enter your last name"}
										marginBottom={1.8}
									/>
									<SignupInput
										name={"email"}
										label={"Email *"}
										placeholder={"Enter your email"}
										marginBottom={1.8}
									/>
									<SignupInput
										name={"phoneNumber"}
										label={"Your Number *"}
										placeholder={"Enter your phone number"}
										marginBottom={1.8}
									/>

									<Box marginBottom={1.8}>
										<Box marginBottom={"10px"}>
											<p className="body fw-bold">Select Role *</p>
										</Box>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Role
											</InputLabel>
											<Select<RoleIndex>
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Age"
												value={formData.role}
												onChange={(e) => {
													actions.setRole(e.target.value as RoleIndex);
												}}	
											>
												<MenuItem value={RoleIndex.PURCHASER}>
													{RoleIndex.PURCHASER.toLowerCase()}
												</MenuItem>
												<MenuItem value={RoleIndex.SALES}>
													{RoleIndex.SALES.toLowerCase()}
												</MenuItem>
											</Select>
										</FormControl>
									</Box>

									<Box marginTop={3}>
										<Button
											variant="contained"
											fullWidth
											size="large"
											sx={{ borderRadius: "10px", height: "51px" }}
											disabled={!formikProps.isValid}
											onClick={() => {
												if (formikProps.isValid) {
													formikProps.submitForm();
												}
											}}
										>
											CONTINUE
										</Button>
									</Box>
								</React.Fragment>
							);
						}}
					</Formik>
				</Box>
				<Box
					display={"flex"}
					justifyContent="center"
					width={"100%"}
					marginTop={2}
				>
					<MobileStepper
						backButton={undefined}
						nextButton={undefined}
						steps={5}
						variant="dots"
						position="static"
						activeStep={actions.getCurrentStepIndex()}
					/>
				</Box>
			</>
		</FormContainer>
	);
}

export default FirstForm;
