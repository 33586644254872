import _ from "lodash";
import React from "react";

export default class ComponentController<T> {
	protected state: T;
	protected __: React.Dispatch<React.SetStateAction<T>>;

	constructor(state: T, setState: React.Dispatch<React.SetStateAction<T>>) {
		this.state = state;
		this.__ = setState;
	}
	protected setState(path: keyof typeof this.state | string, val: any) {
		this.__((p) => {
			const s = { ...p };
			if (typeof s === "object") _.set(s as object, path, val);
			return s;
		});
	}
	protected getState() {
		return this.state;
	}
}
