enum RoleIndex {
	CUSTOMER = "CUSTOMER",
	ADMIN = "ADMIN",
	PURCHASER = "PURCHASER",
	SALES = "SALES",
	MANAGER="MANAGER",
	UNKNOWN = "UNKNOWN",
}

export default RoleIndex;
export type Roles = keyof typeof RoleIndex;
