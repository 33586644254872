import { motion } from "framer-motion";
import React from "react";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import { SignupSteps } from "../../configuration/StepsData";
import AssetIndex from "@src/assets/AssetIndex";

interface BackButtonProps {
	previousScreen: SignupSteps;
}

function BackButtton(p: BackButtonProps) {
	const { actions } = useSignupFormContext();
	return (
		<div>
			<motion.div
				style={{ width: "fit-content", scale: 1.3, cursor: "pointer" }}
				whileHover={{ rotate: 45 }}
				onClick={() => actions.setCurrentStep(p.previousScreen)}
			>
				<AssetIndex.ArrowCircleLeft />
			</motion.div>
		</div>
	);
}

export default BackButtton;
