
import React from "react";
import EmailVerification from "../../Forms/EmailVerification/EmailVerification";
import FirstForm from "../../Forms/FirstForm/FirstForm";
import IdForm from "../../Forms/IdForm/IdForm";
import PasswordForm from "../../Forms/PasswordForm/PasswordForm";
import OtpVerificationForm from "../../Forms/OtpVerificationForm/OtpVerificationForm";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";

function FormMapper() {
	const { actions } = useSignupFormContext();
	const currentForm = actions.getCurrentStep();

	return (
		<>
			{currentForm === "passwordFilling" && <PasswordForm />}
			{currentForm === "idProofUpload" && <IdForm />}
			{currentForm === "emailConfirmation" && <EmailVerification />}
			{currentForm === "otpVerification" && <OtpVerificationForm />}
			{currentForm === "details" && <FirstForm />}
		</>
	);
}

export default FormMapper;
