import { ReactComponent as LohawallaIcon } from "./icons/LohawallaIcon.svg";
import { ReactComponent as UserCircle } from "./icons/UserCircle.svg";
import { ReactComponent as TickIcon } from "./icons/TickIcon.svg";
import { ReactComponent as ErrorIcon } from "./icons/ErrorIcon.svg";
import { ReactComponent as LoginHint } from "./icons/LoginHint.svg";
import { ReactComponent as PasscodeLock } from "./icons/PasscodeLock.svg";
import { ReactComponent as FileLock } from "./icons/FileLock.svg";
import { ReactComponent as FaceId } from "./icons/FaceId.svg";
import { ReactComponent as MailIcon } from "./icons/MailIcon.svg";
import { ReactComponent as Lohawalla_Icon_Dark } from "./icons/Lohawalla_Icon_Dark.svg";
import { ReactComponent as PhonePasswordIcon } from "./icons/PhonePasswordIcon.svg";
import { ReactComponent as ArrowCircleLeft } from "./icons/ArrowCircleLeft.svg";
import HelloHand from "./images/HelloHand.png";

const AssetIndex = {
	LohawallaIcon,
	UserCircle,
	TickIcon,
	ErrorIcon,
	LoginHint,
	PasscodeLock,
	FileLock,
	FaceId,
	MailIcon,
	Lohawalla_Icon_Dark,
	PhonePasswordIcon,
	ArrowCircleLeft
};

export const ImageIndex = {
	HelloHand,
};

export default AssetIndex;
