import { SignupFormType } from "../Types/SignupFormType";
import ComponentController from "../ComponentController";
import sendEmail from "@src/screens/Signup/fetch/services/sendEmail";
import saveCompanyUser from "@src/screens/Signup/fetch/services/saveCompanyUser";
import RoleIndex from "@src/modules/types/Roles.enum";
import SaveImage from "@src/modules/ImageServerUtils/services/SaveImage";
// import { API } from "@src/axios/Instances";

interface RequestQuery {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	phoneNumber: string;
}

const addUser = (d: RequestQuery) =>
	`user/signup?firstName=${d.firstName}&lastName=${d.lastName}&email=${d.email}&phoneNumber=${d.phoneNumber}&password=${d.password}`;

export default class EditFormActions extends ComponentController<SignupFormType> {
	private async internalSendEmail() {
		this.setState("emailVerification.sentStatus", "initiated");
		this.setState("emailVerification.loading", true);
		const res = await sendEmail({ email: this.getState().email });
		this.setState("emailVerification.otp", res.data.otp + "");
	}

	private async saveUserData() {
		const data = this.state;
		let urls = {
			aadhar: "",
			profile: "",
		};
		try {
			const res = await SaveImage(
				data.aadharCard ? [data.aadharCard] : data.aadharCard
			);
			if (res) {
				const data = res.data;
				urls.aadhar = data[0];
			}
		} catch (err) {}

		try {
			const res = await SaveImage(
				data.profilePhoto ? [data.profilePhoto] : data.profilePhoto
			);
			if (res) {
				const data = res.data;
				urls.profile = data[0];
			}
		} catch (err) {}

		await saveCompanyUser({
			name: data.firstName + " " + data.lastName,
			aadhar: urls.aadhar,
			profile: urls.profile,
			role: data.role,
			password: data.password,
			email: data.email,
			phoneNumber: data.phoneNumber,
			searches: 0,
			isVerified: false,
		});
	}

	sendEmail() {
		this.internalSendEmail().then((v) => {
			this.setState("emailVerification.loading", false);
			this.setState("emailVerification.sentStatus", "sentSuccess");
			this.setState("emailVerification.message", "email sent successfully");
			this.setState("emailVerification.disabled", false);
		});
	}

	setOtp(otp: string) {
		this.setState("emailVerification.inputOtp", otp);
	}

	async checkOtp() {
		if (
			this.state.emailVerification.otp + "" ===
			this.state.emailVerification.inputOtp
		) {
			this.setState("emailVerification.message", "email successfully verified");
			this.setState("emailVerification.sentStatus", "verifiedSuccess");

			//? saving user data;
			try {
				this.setState("emailVerification.sentStatus", "saveInitialized");
				this.setState("emailVerification.message", "saving your data...");
				await this.saveUserData();
				this.setState("emailVerification.sentStatus", "saveSuccess");
				this.setState("emailVerification.message", "saved successfully :)");
				return true;
			} catch (err) {
				this.setState("emailVerification.sentStatus", "saveFailed");
				this.setState("emailVerification.message", "some error occured :/");
				return false;
			}
		} else {
			this.setState(
				"emailVerification.message",
				"otp invalid, re-enter correct otp"
			);
			this.setState("emailVerification.sentStatus", "verifiedFailed");
			return false;
		}
	}

	getEmailVerificationStatus() {
		return {
			status: this.state.emailVerification.sentStatus,
			message: this.state.emailVerification.message,
		};
	}
	getIsLoading() {
		return this.state.emailVerification.loading;
	}
}
