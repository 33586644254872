import { Box, MobileStepper } from "@mui/material";
import React from "react";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";

function Stepper() {
	const { actions } = useSignupFormContext();
	return (
		<Box display={"flex"} justifyContent="center" width={"100%"} marginTop={2}>
			<MobileStepper
				backButton={undefined}
				nextButton={undefined}
				steps={5}
				variant="dots"
				position="static"
				activeStep={actions.getCurrentStepIndex()}
			/>
		</Box>
	);
}

export default Stepper;
