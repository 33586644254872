import apiIndex from "../apis";
import SignupInstance from "../instance";

interface getOtp {
	phoneNumber: string;
}

export default async function getOtp(data: getOtp) {
	return SignupInstance.post<number>(apiIndex.getOtp, data);
}
