export default interface DetailsFormFields {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
}

export const DetailsFormFieldsInitial: DetailsFormFields = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
};
