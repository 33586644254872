import { Box, Button, MobileStepper } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import BackButtton from "../../components/BackButtton/BackButtton";
import FormHeader from "@src/components/FormHeader.tsx/FormHeader";
import AssetIndex from "@src/assets/AssetIndex";
import SignupInput from "@src/components/SignupInput/SignupInput";

const validationSchema = yup
	.object({
		password: yup
			.string()
			.min(8, "password must be of atleast 8 characters")
			.required(),
		confirmPassword: yup.string().required(),
	})
	.test(function (v) {
		const err = this.createError({
			path: "confirmPassword",
			message: "password don't match",
		});
		if (v.confirmPassword !== v.password) {
			return err;
		}
	});

function PasswordForm() {
	const { actions } = useSignupFormContext();
	const formData = actions.getData();
	return (
		<FormContainer>
			<BackButtton previousScreen={"otpVerification"} />
			<FormHeader
				heading={"Choose Password"}
				subHeading={"Please provide your name and email"}
				icon={<AssetIndex.PasscodeLock />}
			/>
			<Formik
				initialValues={{
					password: formData.password,
					confirmPassword: formData.password,
				}}
				onSubmit={function (values, formikHelpers) {
					actions.setPassword(values.password);
					actions.setCurrentStep("idProofUpload");
				}}
				validationSchema={validationSchema}
				validateOnMount={true}
			>
				{(formikProps) => {
					return (
						<>
							<Box display={"flex"} flexDirection="column">
								<SignupInput
									label={"Password*"}
									name={"password"}
									placeholder="Enter password"
									marginBottom={1}
								/>
								<SignupInput
									label={"Confirm Password*"}
									name={"confirmPassword"}
									placeholder="Confirm Password"
									marginBottom={1}
								/>
								<Box marginTop={10}>
									<Button
										size="large"
										fullWidth
										variant="contained"
										disabled={!formikProps.isValid}
										onClick={() => {
											if (formikProps.isValid) {
												formikProps.submitForm();
											}
										}}
									>
										Continue
									</Button>
								</Box>
							</Box>
							<Box
								display={"flex"}
								justifyContent="center"
								width={"100%"}
								marginTop={2}
							>
								<MobileStepper
									backButton={undefined}
									nextButton={undefined}
									steps={5}
									variant="dots"
									position="static"
									activeStep={actions.getCurrentStepIndex()}
								/>
							</Box>
						</>
					);
				}}
			</Formik>
		</FormContainer>
	);
}

export default PasswordForm;
