import RoleIndex from "@src/modules/authorization/Roles.enum";
import { SignupSteps } from "../../configuration/StepsData";
import ComponentController from "../ComponentController";
import { SignupFormType } from "../Types/SignupFormType";
import { UserIdFiles } from "../Types/UserIdFiles";
import DetailsFormActions from "./DetailsFormActions";
import EditFormActions from "./EditFormActions";

export default class SignupFormActions extends ComponentController<SignupFormType> {
	detailsFormActions: DetailsFormActions;
	emailFormActions: EditFormActions;
	constructor(
		state: SignupFormType,
		__: React.Dispatch<React.SetStateAction<SignupFormType>>
	) {
		super(state, __);
		this.detailsFormActions = new DetailsFormActions(state, __);
		this.emailFormActions = new EditFormActions(state, __);
	}
	setRole(role: RoleIndex) {
		this.setState("role", role);
	}
	getData() {
		return this.state;
	}
	setSentOpt() {
		this.setState("hasSentOtp", true);
	}
	incrementHasSentOtp() {
		this.setState("hasSentOtp", this.state.hasSentOtp + 1);
	}
	setCurrentStep(step: SignupSteps) {
		this.setState("currentStep", step);
	}
	getCurrentStep() {
		return this.state.currentStep;
	}
	getCurrentStepIndex() {
		const obj: Record<SignupSteps, number> = {
			details: 0,
			otpVerification: 1,
			passwordFilling: 2,
			idProofUpload: 3,
			emailConfirmation: 4,
		};
		return obj[this.getCurrentStep()];
	}

	setPassword(password: string) {
		this.setState("password", password);
	}
	setIdFiles(files: UserIdFiles) {
		this.setState("aadharCard", files.aadharCard);
		this.setState("profilePhoto", files.profilePhoto);
	}
	setPhoneNumberVerified() {
		this.setState("hasVerifiedOtp", true);
	}
	isPhoneNumberVerified() {
		return this.state.hasVerifiedOtp;
	}
}
