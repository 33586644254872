import React from "react";
import Login from "./screens/Login/Login";
import Signup from "./screens/Signup/Signup";
import { Route, Routes } from "react-router-dom";

function App() {

	return (
		<Routes>
			<Route index element={<Login />} />
			<Route path="/signup" element={<Signup />} />
		</Routes>
	);
}

export default App;
