import { Box } from "@mui/material";
import React from "react";
import CheckCircle from "../CheckCircle/CheckCircle";

interface SignupStepData {
	heading: string;
	desc: string;
	isActive: boolean;
}
 

function StepListItem({
	data,
	marginBottom,
	classNames: cns,
}: {
	data: SignupStepData;
	marginBottom?: number;
	classNames?: {
		heading?: {
			active: string;
			inActive: string;
		};
		desc?: {
			active: string;
			inActive: string;
		};
	};
}) {
	type State = "active" | "inActive";
	const classNames_ = {
		heading: {
			active: "fcolor-fuschia",
			inActive: "fcolor-text-subtitle",
		},
		desc: {
			active: "fcolor-text-body",
			inActive: "fcolor-text-subtitle",
		},
	};

	const classNames = {
		...classNames_,
		...cns,
	};

	const state: State = data.isActive ? "active" : "inActive";

	return (
		<Box display="flex" alignItems={"center"} marginBottom={marginBottom}>
			<Box marginRight={2}>
				<CheckCircle isActive={data.isActive} />
			</Box>
			<Box>
				<p className={"header-3 " + classNames.heading[state]}>{data.heading}</p>
				<p className={"body " + classNames.desc[state]}>{data.desc}</p>
			</Box>
		</Box>
	);
}

export default StepListItem;
