import React, { useContext, useState } from "react";
import SignupFormActions from "../actions/SignupFormActions";
import  {
	DetailsFormFieldsInitial,
} from "../Types/DetailsFormFields";
import { SignupFormType } from "../Types/SignupFormType";
import RoleIndex from "@src/modules/authorization/Roles.enum";

const SignupFormContextValue = React.createContext(
	{} as { actions: SignupFormActions }
);

export function useSignupFormContext() {
	return useContext(SignupFormContextValue);
}

function SignupFormContext(p: { children: React.ReactNode }) {
	const [formValues, setFormValues] = useState<SignupFormType>({
		...DetailsFormFieldsInitial,
		currentStep: "details",
		password: "",
		aadharCard: null,
		profilePhoto: null,
		emailVerification: {
			loading: false,
			otp: "",
			sentStatus: "dormant",
			message: "",
			disabled: true,
			inputOtp: "",
		},
		hasSentOtp: 0,
		hasVerifiedOtp: false,
		role: RoleIndex.SALES,
	});

	const signupActions = new SignupFormActions(formValues, setFormValues);

	return (
		<SignupFormContextValue.Provider value={{ actions: signupActions }}>
			{p.children}
		</SignupFormContextValue.Provider>
	);
}

export default SignupFormContext;
