import RoleIndex from "@src/modules/types/Roles.enum";
import SignupInstance from "../instance";
import apiIndex from "../apis";

interface UserData {
	name: string;
	aadhar: string;
	profile: string;
	role: RoleIndex;
	password: string;
	email: string;
	phoneNumber: string;
	searches: number;
	isVerified: boolean;
}

export default async function saveCompanyUser(data: UserData) {
	return SignupInstance.post(apiIndex.createCompanyUser, data);
}
