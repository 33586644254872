import {
	Box,
	Button,
	Card,
	CircularProgress,
	Input,
	Snackbar,
} from "@mui/material";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import { useNavigate } from "react-router-dom";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import BackButtton from "../../components/BackButtton/BackButtton";
import FormHeader from "@src/components/FormHeader.tsx/FormHeader";
import Stepper from "../../components/Stepper/Stepper";
import AssetIndex from "@src/assets/AssetIndex";

function EmailVerification() {
	const { actions } = useSignupFormContext();
	const { emailFormActions } = actions;
	const { status, message } = emailFormActions.getEmailVerificationStatus();
	const navigate = useNavigate();

	return (
		<FormContainer>
			<BackButtton previousScreen={"idProofUpload"} />
			<FormHeader
				heading={"Email Confirmation"}
				subHeading={"Upload the required documents"}
				icon={<AssetIndex.MailIcon/>}
			/>
			<Box display={"flex"} justifyContent="center" marginBottom={6}>
				<p
					className="subtitle"
					style={{ width: "fit-content", textAlign: "center" }}
				>
					Thank you for signing up to the Lohawalla
				</p>
			</Box>
			<Box marginBottom={6}>
				<p className="body fcolor-text-body" style={{ textAlign: "center" }}>
					Please click the link below to activate and access your profile.
					Logging in will enable you to start an application for data.
				</p>
			</Box>
			<Box
				display="flex"
				justifyContent={"center"}
				marginBottom={3}
				alignItems="center"
			>
				<Box marginRight={2}>
					<Button
						variant="outlined"
						onClick={() => {
							emailFormActions.sendEmail();
						}}
					>
						click to activate
					</Button>
				</Box>
				<Box>
					{emailFormActions.getIsLoading() && (
						<CircularProgress size={"20px"} />
					)}
				</Box>
			</Box>
			<Box display="flex" justifyContent={"center"} marginBottom={3}>
				<Input
					placeholder="enter otp"
					sx={{ textAlign: "center" }}
					disabled={actions.getData().emailVerification.disabled}
					onChange={(e) => emailFormActions.setOtp(e.target.value)}
				/>
			</Box>

			{actions.getData().emailVerification.sentStatus === "saveInitialized" && (
				<Card variant="outlined">
					<Box display="flex" padding={2} justifyContent="space-between">
						<Box margin-right={2}>
							<p className="body fcolor-iris">SavingYour Data</p>
						</Box>
						<Box margin-right={2}>
							<CircularProgress size={"20px"} />
						</Box>
					</Box>
				</Card>
			)}

			<Box marginTop={3}>
				<Button
					variant="contained"
					fullWidth
					size="large"
					sx={{ borderRadius: "10px", height: "51px" }}
					disabled={
						actions.getData().emailVerification.disabled ||
						actions.getData().emailVerification.inputOtp === ""
					}
					onClick={() =>
						emailFormActions.checkOtp().then((d) => {
							if(d) window.location.replace("https://www.lohawalla.com/");
						})
					}
				>
					CONTINUE
				</Button>
			</Box>
			<Stepper />
			<Snackbar
				open={
					status === "sentError" ||
					status === "sentSuccess" ||
					status === "verifiedFailed" ||
					status === "verifiedSuccess" ||
					status === "saveSuccess" ||
					status === "saveFailed"
				}
				message={message}
			/>
		</FormContainer>
	);
}

export default EmailVerification;
