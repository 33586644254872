import { Box, Grid } from "@mui/material";
import React from "react";
import getStepDataList from "../../configuration/StepsData";
import style from "./Layout.module.css";
import StepListItem from "@src/components/StepListItem/StepListItem";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import AssetIndex, { ImageIndex } from "@src/assets/AssetIndex";

function SignupLayout(p: { children?: React.ReactNode }) {
	const marginBottom = 3;
	const steps = getStepDataList();
	const { actions } = useSignupFormContext();
	return (
		<Grid container className={style.container}>
			<Grid item xs={5}>
				<Box paddingLeft={7} paddingTop={7} marginBottom={12}>
					<AssetIndex.Lohawalla_Icon_Dark />
				</Box>
				<Box display={"flex"} flexDirection="column" alignItems={"center"}>
					<Box>
						<Box display={"flex"} marginBottom={5} alignItems={"center"}>
							<Box marginRight={2}>
								<img
									src={ImageIndex.HelloHand}
									style={{ transform: "scale(1.5)" }}
								/>
							</Box>
							<Box>
								<p className="header-2 fcolor-iris">SIGN UP</p>
							</Box>
						</Box>
						<Box>
							{steps.map((v, i) => (
								<StepListItem
									data={{
										heading: v.heading,
										desc: v.desc,
										isActive: actions.getCurrentStepIndex() >= i,
									}}
									marginBottom={marginBottom}
									key={i}
								/>
							))}
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={7} sx={{ overflow: "auto" }} height="100%">
				{p.children}
			</Grid>
		</Grid>
	);
}

export default SignupLayout;
