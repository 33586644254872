import LoginInstance from "../instance";
import apiIndex from "../apis";
import RoleIndex from "@src/modules/authorization/Roles.enum";

interface LogInCredentials {
	email: string;
	password: string;
	userAgent: string;
	platform: string;
}
interface LoginData {
	success: boolean;
	userId: string;
	createdAt: Date;
	maxAge: Date;
	role: RoleIndex;
	token: string;
	name: string;
	email: string;
	phoneNumber: string;
}
export default async function login(data: LogInCredentials) {
	return LoginInstance.post<LoginData>(apiIndex.companyUserLogin, data);
}
