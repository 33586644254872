import ComponentController from "../ComponentController";
import DetailsFormFields from "../Types/DetailsFormFields";
import { SignupFormType } from "../Types/SignupFormType";

export default class DetailsFormActions extends ComponentController<SignupFormType> {
	setDetails(data: DetailsFormFields) {
		this.__((p) => {
			const s = { ...p };
			s.email = data.email;
			s.firstName = data.firstName;
			s.lastName = data.lastName;
			s.phoneNumber = data.phoneNumber;
			return s;
		});
	}
}
