import { Box } from "@mui/material";
import React from "react";
import style from "./FormHeader.module.css";

interface FormHeaderProps {
	heading: string;
	subHeading: string;
	icon: React.ReactNode;
	marginBottom?: number;
}

function FormHeader(p: FormHeaderProps) {
	const marginBottom = p.marginBottom ? p.marginBottom : 6;
	return (
		<Box
			width={"100%"}
			display="flex"
			alignItems="center"
			justifyContent={"center"}
			flexDirection={"column"}
			marginBottom={marginBottom}
		>
			<Box marginBottom={"4px"}>{p.icon}</Box>
			<Box marginBottom={"1px"}>
				<p className={style.gradientText + " header-2 fcolor-fuschia"}>
					{p.heading}
				</p>
			</Box>
			<Box>
				<p className="body fcolor-text-body">{p.subHeading}</p>
			</Box>
		</Box>
	);
}

export default FormHeader;
