import { Box } from "@mui/material";
import React from "react";

interface FormHeaderProps {
	heading: string;
	subHeading: string;
	icon: React.ReactNode;
	marginBottom?: number;
}

function FormHeader(p: FormHeaderProps) {
	const marginBottom = p.marginBottom ? p.marginBottom : 6;
	return (
		<Box
			width={"100%"}
			display="flex"
			alignItems="center"
			justifyContent={"center"}
			flexDirection={"column"}
			marginBottom={marginBottom}
		>
			<Box marginBottom={"4px"}>{p.icon}</Box>
			<Box marginBottom={"1px"}>
				<p className="h2 text-slate-700">{p.heading}</p>
			</Box>
			<Box>
				<p className="body text-slate-500">{p.subHeading}</p>
			</Box>
		</Box>
	);
}

export default FormHeader;
