import { Box, Button, Hidden } from "@mui/material";
import React, { useState } from "react";
import Stepper from "../../components/Stepper/Stepper";
import style from "./IdForm.module.css";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import { UserIdFiles } from "../../stateManagement/Types/UserIdFiles";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import BackButtton from "../../components/BackButtton/BackButtton";
import FormHeader from "@src/components/FormHeader.tsx/FormHeader";
import AssetIndex from "@src/assets/AssetIndex";

function isPDF(name: string) {
	const n = name.length;
	const extension = name[n - 3] + name[n - 2] + name[n - 1];
	console.log(extension);
	return extension === "pdf";
}

function IdForm() {
	const data = useSignupFormContext().actions.getData();
	const [{ aadharCard, profilePhoto }, setFiles] = useState<UserIdFiles>({
		aadharCard: data.aadharCard,
		profilePhoto: data.profilePhoto,
	});

	const { actions } = useSignupFormContext();

	return (
		<FormContainer>
			<BackButtton previousScreen={"passwordFilling"} />
			<FormHeader
				heading={"Upload Your Aadhar"}
				subHeading={"Upload the required documents"}
				icon={<AssetIndex.FaceId />}
			/>
			<Box marginBottom={1}>
				{aadharCard ? (
					isPDF(aadharCard.name) ? (
						<iframe src={URL.createObjectURL(aadharCard)} width={"100%"} />
					) : (
						<img src={URL.createObjectURL(aadharCard)} width={200} />
					)
				) : (
					<></>
				)}
			</Box>
			<Box
				sx={{
					minHeight: "40px",
					borderRadius: "12px",
					border: "1px solid var(--text-subtitle)",
					padding: "8px",
					paddingLeft: "12px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
				marginBottom={3}
			>
				<Box>
					{aadharCard ? (
						<div className="flex"	>
							<p
								className="body fcolor-fuschia"
								style={{ maxWidth: "300px", overflow: "hidden" }}
							>
								{aadharCard.name}
							</p>
							<span>...</span>
						</div>
					) : (
						<p className="body fcolor-text-subtitle">Upload your aadhar card</p>
					)}
				</Box>
				<Box className={style.fileInputArea}>
					<Button variant="contained">upload</Button>
					<input
						type={"file"}
						className={style.fileInput}
						onChange={(e) => {
							const fileList = e.target.files;
							if (fileList !== null) {
								setFiles((p) => ({ ...p, aadharCard: fileList[0] }));
							}
						}}
						accept=".png,.pdf,.jpg"
					/>
				</Box>
			</Box>

			<Box marginBottom={1}>
				{profilePhoto ? (
					<img src={URL.createObjectURL(profilePhoto)} width={200} />
				) : (
					<></>
				)}
			</Box>
			<Box
				sx={{
					minHeight: "40px",
					borderRadius: "12px",
					border: "1px solid var(--text-subtitle)",
					padding: "8px",
					paddingLeft: "12px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box>
					{profilePhoto ? (
						<div className="flex"	>
						<p
							className="body fcolor-fuschia"
							style={{ maxWidth: "300px", overflow: "hidden" }}
						>
							{profilePhoto.name}
						</p>
						<span>...</span>
					</div>
					) : (
						<p className="body fcolor-text-subtitle">
							Upload your profile photo
						</p>
					)}
				</Box>
				<Box className={style.fileInputArea}>
					<Button variant="contained">upload</Button>
					<input
						type={"file"}
						className={style.fileInput}
						onChange={(e) => {
							const fileList = e.target.files;
							if (fileList !== null) {
								setFiles((p) => ({ ...p, profilePhoto: fileList[0] }));
							}
						}}
						accept=".png,.jpg"
					/>
				</Box>
			</Box>
			<Box marginTop={5}>
				<Button
					variant="contained"
					fullWidth
					size="large"
					sx={{ borderRadius: "10px", height: "51px" }}
					disabled={!aadharCard || !profilePhoto}
					onClick={() => {
						actions.setIdFiles({ aadharCard, profilePhoto });
						actions.setCurrentStep("emailConfirmation");
					}}
				>
					upload
				</Button>
			</Box>
			<Stepper />
		</FormContainer>
	);
}

export default IdForm;
