import { ServerStateUtils } from "@src/modules/StateManagement/Core/StateUtils";
import login from "../fetch/services/login";
import RoleIndex from "@src/modules/authorization/Roles.enum";
import getIsLoggedIn from "../fetch/services/getIsLoggedIn";

export default class LoginActions extends ServerStateUtils<Login.State> {
	setEmail(data: string) {
		this.mutateState((p) => {
			p.userName.value = data;
		});
	}
	setPassword(data: string) {
		this.mutateState((p) => {
			p.password.value = data;
		});
	}

	async login() {
		const res = await this.handleAsync(
			"login",
			() =>
				login({
					email: this.state.userName.value,
					password: this.state.password.value,
					userAgent: this.state.userAgent,
					platform: this.state.platform,
				}),
			{
				errMessage: (err) => {
					if (err.response) {
						return (err.response.data as { message: string }).message;
					}
					if (err.status) {
						if (err.status === 403) {
							return "invalid credentials";
						}
					}
					return "server error, please try again or contact support";
				},
				successMessage: 'Welcome'
			}
		);
		if (res) {
			const data = res.data;
			const { loginData }:any = data;
			const { role } = loginData;
			window.localStorage.setItem("userData", JSON.stringify(data));
			if (role === RoleIndex.PURCHASER) {
				window.location.replace("/purchaser");
				// window.location.replace("https://lohawala.onrender.com/purchaser");
			} else if (role === RoleIndex.ADMIN) {
				window.location.replace("/admin");
				// window.location.replace("https://lohawala.onrender.com/admin");
			} else if (role === RoleIndex.SALES) {
				window.location.replace("/sales");
				// window.location.replace("https://lohawala.onrender.com/sales");
			}
			 else if (role === RoleIndex.MANAGER) {
				window.location.replace("/godown");
				// window.location.replace("https://lohawala.onrender.com/sales");
			}
		}
	}

	async isLoggedIn() {
		const res = await this.handleAsync("getIsLoggedIn", () => getIsLoggedIn());
		if (res) {
			const data = res.data;
			const { loginData } = data;
			const { role } = loginData;
			window.localStorage.setItem("userData", JSON.stringify(data));
			if (role === RoleIndex.PURCHASER) {
				window.location.replace("https://www.lohawalla.com/purchaser");
				// window.location.replace("https://lohawala.onrender.com/purchaser");
			} else if (role === RoleIndex.ADMIN) {
				window.location.replace("https://www.lohawalla.com/admin");
				// window.location.replace("https://lohawala.onrender.com/admin");
			} else if (role === RoleIndex.SALES) {
				window.location.replace("https://www.lohawalla.com/sales");
				// window.location.replace("https://lohawala.onrender.com/sales");
			}
			 else if (role === RoleIndex.MANAGER) {
				window.location.replace("https://www.lohawalla.com/godown");
				// window.location.replace("https://lohawala.onrender.com/sales");
			}
		}
	}
}
