import { Box, Button, Input, Snackbar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Stepper from "../../components/Stepper/Stepper";
import { useSignupFormContext } from "../../stateManagement/context/SignupFormContext";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import BackButtton from "../../components/BackButtton/BackButtton";
import AssetIndex from "@src/assets/AssetIndex";
import FormHeader from "@src/components/FormHeader.tsx/FormHeader";
import getOtp from "@src/screens/Signup/fetch/services/getOtp";
import verifyOtp from "@src/screens/Signup/fetch/services/verifyOtp";

function OtpVerificationForm() {
	const { actions } = useSignupFormContext();
	const otpRef = useRef({ hasSent: false });
	const [otp, setOtp] = useState("");
	const [validity, setValidity] = useState({
		hasSubmitted: false,
		isValid: false,
	});
	const [showSnackBar, setShowSnackBar] = useState({
		show: false,
		message: "",
	});

	useEffect(() => {
		if (actions.getData().hasSentOtp === 0) {
			getOtp({ phoneNumber: actions.getData().phoneNumber })
				.then((v) => {
					setShowSnackBar({
						show: true,
						message: "otp sent successfully",
					});
					actions.setSentOpt();
				})
				.catch((err) => {
					setShowSnackBar({
						show: true,
						message: "failed to send opt, refresh and try again",
					});
				});
		}
	}, []);

	useEffect(() => {
		if (validity.hasSubmitted && actions.getData().hasSentOtp) {
			if (actions.getData().hasVerifiedOtp) {
				actions.setCurrentStep("passwordFilling");
				return;
			}
			verifyOtp({ otp, phoneNumber: actions.getData().phoneNumber })
				.then((v) => {
					console.log("checking");
					if (v.data) {
						setShowSnackBar({ show: true, message: "phone number verified" });
						actions.setPhoneNumberVerified();
						setTimeout(() => {
							actions.setCurrentStep("passwordFilling");
						}, 1000);
					} else {
						setShowSnackBar({
							show: true,
							message: "otp incorrect, try again or re enter after refreshing",
						});
					}
				})
				.catch((err) => {
					setShowSnackBar({
						show: true,
						message: "server error, try again later",
					});
				});
		}
	}, [validity]);

	return (
		<FormContainer>
			<BackButtton previousScreen={"details"} />
			<FormHeader
				heading={"OTP Verification"}
				subHeading={"Enter OTP form registered mobile number"}
				icon={<AssetIndex.FileLock />}
			/>
			<Box display={"flex"} justifyContent="center" marginBottom={4}>
				<AssetIndex.PhonePasswordIcon />
			</Box>
			<Box marginBottom={4}>
				<p className="body fcolor-fuschia" style={{ textAlign: "center" }}>
					Enter the OTP sent to
					<span className="fw-bold">{actions.getData().phoneNumber}</span>
				</p>
			</Box>
			<Box display="flex" justifyContent={"center"}>
				<Input
					onChange={(e) => setOtp(e.target.value)}
					error={otp.length === 0 && validity.hasSubmitted}
				/>
			</Box>
			<Box marginTop={3}>
				<Button
					disabled={!actions.getData().hasSentOtp&& false}
					variant="contained"
					fullWidth
					size="large"
					sx={{ borderRadius: "10px", height: "51px" }}
					onClick={() => {
						setValidity((p) => ({ ...p, hasSubmitted: true }));
						actions.setCurrentStep("passwordFilling");
					}}
				>
					CONTINUE
				</Button>
			</Box>
			<Stepper />
			<Snackbar open={showSnackBar.show} message={showSnackBar.message} />
		</FormContainer>
	);
}

export default OtpVerificationForm;
