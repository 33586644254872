import React from 'react'
import { Box } from "@mui/material";
import SignupFormContext from './signup/stateManagement/context/SignupFormContext';
import SignupLayout from './signup/components/Layout/Layout';
import FormMapper from './signup/components/FormMapper/FormMapper';

export default function Signup() {

	return (
		<SignupFormContext>
			<SignupLayout>
				<Box
					sx={{ width: "100%", minHeight: "100%" }}
					display="flex"
					justifyContent={"center"}
					// alignItems="center"
					paddingTop={8}
				>
					<FormMapper/>
				</Box>
			</SignupLayout>
		</SignupFormContext>
	);
}