import { Button, CircularProgress, Snackbar } from "@mui/material";
import AssetIndex, { ImageIndex } from "@src/assets/AssetIndex";
import FieldInput from "@src/components/FieldInput/FieldInput";
import FormHeader from "@src/components/FormHeader/FormHeader";
import { FieldDataService } from "@src/modules/FieldData/FieldData";
import AsyncStateFactory from "@src/modules/StateManagement/AsyncState/AsyncStateFactory";
import React, { useContext, useEffect, useState } from "react";
import Validate from "./actions/Validate";
import LoginActions from "./actions/LoginActions";
import FormContainer from "@src/components/FormContainerLogin/FormContainer";
import { Link } from "react-router-dom";
import { basePath } from "@src/modules/axios/AxiosFactory";

interface Props {}
interface ContextProps {
  validate: Validate;
  state: Login.State;
}
const LoginContext = React.createContext({} as ContextProps);
const useLoginContext = () => useContext(LoginContext);

export default function Login(props: Props) {
  const [state, setState] = useState<Login.State>({
    userName: FieldDataService.getDefaultField(),
    password: FieldDataService.getDefaultField(),
    userAgent: navigator.userAgent,
    platform: navigator.platform,

    loading: {
      login: AsyncStateFactory(),
    },
  });
  const validate = new Validate(state, setState);
  const loginActions = new LoginActions(state, setState);

  return (
    <div className="w-full flex overflow-hidden" style={{ height: "100vh" }}>
      <div className="basis-1/2 bg-fuschia p-12">
        <div style={{ marginBottom: 100 }}>
          <AssetIndex.LohawallaIcon />
        </div>
        <div className="mb-12">
          <div className="flex">
            <p className="text-xl text-slate-400 font-medium mr-5">
              Welcome to
            </p>
            <img src={ImageIndex.HelloHand} alt="" />
          </div>
          <h1 className="text-4xl font-bold text-white">Lohawalla</h1>
        </div>
        <div>{/* <AssetIndex.LoginHint /> */}</div>
      </div>
      <div className="basis-1/2 h-full p-16 flex items-center justify-center overflow-auto">
        <FormContainer>
          <div className="mb-12">
            <FormHeader
              heading={"LOGIN"}
              subHeading={"Login portal for admin, sales and purchaser"}
              icon={<AssetIndex.UserCircle />}
            />
          </div>
          <div className="p-5">
            <div className="mb-8 h-12">
              <FieldInput
                {...state.userName}
                onChange={(d) => {
                  loginActions.setEmail(d.target.value);
                }}
                type={"text"}
                placeHolder={"enter email id"}
              />
            </div>
            <div className="mb-12">
              <FieldInput
                {...state.password}
                onChange={(d) => {
                  loginActions.setPassword(d.target.value);
                }}
                type={"password"}
                placeHolder={"enter password"}
              />
            </div>
            <div>
              <Button
                fullWidth
                variant="contained"
                sx={{ paddingTop: 2, paddingBottom: 2, marginBottom: "8px" }}
                onClick={() => {
                  const verdict = validate.validateLogin();
                  if (verdict) {
                    loginActions.login();
                  }
                }}
              >
                {state.loading.login.status === "initialized" ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : (
                  "Login"
                )}
              </Button>
              <div className="flex justify-end cursor-pointer group">
                <Link to={"/signup"}>
                  <p className="text-sm font-medium text-slate-500 group-hover:text-indigo-500 select-none">
                    dont have an account? Signup
                  </p>
                </Link>
              </div>
              <div
                className="flex justify-end cursor-pointer group"
                onClick={() =>
                  window.location.replace(
                    "https://www.lohawalla.com/hrms/login"
                  )
                }
              >
                <p className="text-sm font-medium text-slate-500 group-hover:text-indigo-500 select-none">
                  Switch To HRMS
                </p>
              </div>
            </div>
          </div>
        </FormContainer>
      </div>
      <Snackbar
        open={
          state.loading.login.status === "success" ||
          state.loading.login.status === "failed"
        }
        message={state.loading.login.message}
      />
    </div>
  );
}
