export const StepData = {
	details: {
		heading: "Your Details",
		desc: "Please provide your name and email",
	},
	otpVerification: {
		heading: "OTP Verification",
		desc: "Enter OTP from registered mobile number",
	},
	passwordFilling: {
		heading: "Choose a Password",
		desc: "Must be at least 8 characters",
	},
	idProofUpload: {
		heading: "Upload ID Proof",
		desc: "Upload the required documents",
	},
	emailConfirmation: {
		heading: "Email Confirmation",
		desc: "Your account will be verified through email",
	},
};

export default function getStepDataList() {
	let arr: {stepName: string, heading: string, desc: string}[] = [];
  for(let v of Object.keys(StepData)) {
    const d = StepData[v as SignupSteps];
    arr.push({stepName: v, ...d});
  }
  return arr;
}

export type StepDataObj = typeof StepData;
export type SignupSteps = keyof StepDataObj;
